import React from "react";
import { FormattedMessage } from "react-intl";
import aboutImage1 from "../assets/images/heroimg.jpg";
import aboutImage2 from "../assets/images/heroimg.jpg"; // Add this image
import aboutImage3 from "../assets/images/heroimg.jpg"; // Add this image

function About() {
    return (
        <div className="flex-grow">
            <main className="flex flex-col">
                {/* Top section */}
                <section className="bg-white py-20">
                    <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
                        <div className="w-full md:w-2/5 mb-8 md:mb-0">
                            <div className="max-w-[300px] aspect-square overflow-hidden rounded-full relative mx-auto md:mx-0">
                                <img
                                    src={aboutImage1}
                                    alt="About"
                                    className="w-full h-full object-cover object-center"
                                />
                            </div>
                        </div>
                        <div className="w-full md:w-3/5 text-center md:text-left md:pl-8 font-marcellus">
                            <p className="text-[#171c2f] mb-6 italic">
                                <FormattedMessage id="about.subtitle" />
                            </p>
                            <p className="mb-4 font-bold">
                                <FormattedMessage id="about.t1" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="about.p1" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="about.p1.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="about.p1.b" />
                            </p>
                        </div>
                    </div>
                </section>

                {/* Middle section */}
                <section className="bg-[#171c2f] py-20 text-[#f8f9fa]">
                    <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
                        <div className="w-full md:w-3/5 text-center md:text-left font-marcellus order-2 md:order-1">
                            <p className="font-bold mb-4">
                                <FormattedMessage id="about.t3" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="about.p3" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="about.p3.a" />
                            </p>
                        </div>
                        <div className="w-full md:w-2/5 mb-8 md:mb-0 order-1 md:order-2">
                            <div className="max-w-[300px] aspect-square overflow-hidden rounded-lg relative mx-auto md:mx-0 md:ml-auto">
                                <img
                                    src={aboutImage2}
                                    alt="About"
                                    className="w-full h-full object-cover object-center"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Bottom section */}
                <section className="bg-white py-20">
                    <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
                        <div className="w-full md:w-2/5 mb-8 md:mb-0">
                            <div className="max-w-[300px] aspect-square overflow-hidden rounded-lg relative mx-auto md:mx-0">
                                <img
                                    src={aboutImage3}
                                    alt="About"
                                    className="w-full h-full object-cover object-center"
                                />
                            </div>
                        </div>
                        <div className="w-full md:w-3/5 text-center md:text-left md:pl-8 font-marcellus">
                            <p className="font-bold mb-4">
                                <FormattedMessage id="about.t4" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="about.p4" />
                            </p>
                            <p className="font-bold mb-4">
                                <FormattedMessage id="about.t5" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="about.p5" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="about.p5.a" />
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default About;
