import React from "react";
import { FormattedMessage } from "react-intl";
import heroImage from "../assets/images/hero2.jpg";

function Hero() {
    const handleScrollToContact = (e) => {
        e.preventDefault();
        const contactSection = document.querySelector("#contact");
        if (contactSection) {
            const offset = 30;
            const elementPosition = contactSection.getBoundingClientRect().top;
            const offsetPosition =
                elementPosition + window.pageYOffset - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    return (
        <section id="hero" className="w-full bg-[#ede6d6] font-pt pt-24 py-16">
            <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-center">
                <div className="w-full md:w-1/2 mb-8 md:mb-0">
                    <div className="max-w-[300px] sm:max-w-[400px] md:max-w-[500px] aspect-square overflow-hidden rounded-full relative mx-auto md:mx-0">
                        <img
                            src={heroImage}
                            alt="Hero"
                            className="w-full h-full object-cover object-center"
                            style={{ objectPosition: "center 30%" }}
                        />
                    </div>
                </div>
                <div className="w-full md:w-1/2 text-center md:text-left md:pl-8">
                    <div className="text-[#000000]">
                        <h2 className="mb-4 fon">
                            <FormattedMessage id="hero.title" />
                        </h2>
                        <p className="mb-4">
                            <FormattedMessage id="hero.description" />
                        </p>
                        <ul className="mb-4">
                            <li className="flex items-center before:content-['-'] before:mr-2">
                                <span className="font-semibold">
                                    <FormattedMessage id="hero.point1" />
                                </span>
                                <span>&nbsp;</span>
                                <FormattedMessage id="hero.point1a" />
                            </li>
                            <li className="flex items-center before:content-['-'] before:mr-2">
                                <span className="font-semibold">
                                    <FormattedMessage id="hero.point2" />
                                </span>
                                <span>&nbsp;</span>
                                <FormattedMessage id="hero.point2a" />
                            </li>
                        </ul>
                        <div className="mb-4 flex items-center">
                            <p>
                                <FormattedMessage id="hero.description2" />
                            </p>
                            <button
                                onClick={() =>
                                    (window.location.href = "/about")
                                }
                                className="ml-4 text-base bg-[#000000] text-white font-semibold hover:text-opacity-90 py-2 px-6 rounded-full transition duration-300 inline-block relative z-20"
                            >
                                <FormattedMessage id="hero.button" />
                            </button>
                        </div>
                        <p className="mb-4">
                            <FormattedMessage id="hero.description3" />
                        </p>
                        <p className="mb-4">
                            <FormattedMessage id="hero.description4" />
                        </p>
                        <p className="mb-4 italic">
                            <FormattedMessage id="hero.description5" />
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
