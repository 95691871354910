import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import "../index.css";

function Contact() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        service: [], // Change to an array for multiple selections
    });
    const [errorMessage, setErrorMessage] = useState(""); // State for error message

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]:
                type === "checkbox"
                    ? checked
                        ? [...prevState.service, value] // Add value if checked
                        : prevState.service.filter((item) => item !== value) // Remove value if unchecked
                    : value,
        }));
        setErrorMessage(""); // Clear error message on change
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validation logic
        const { firstName, lastName, email, message, service } = formData;
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // Simple email validation

        if (
            !firstName ||
            !lastName ||
            !isEmailValid ||
            !message ||
            service.length === 0
        ) {
            setErrorMessage(
                "Please fill in all required fields and select at least one service."
            );
            return;
        }

        // Handle form submission logic here
        console.log(formData);
    };

    return (
        <section id="contact" className="py-20 relative bg-[#ffffff]">
            <div className="absolute inset-0 grainy-custom-bg-light"></div>
            <div className="container mx-auto relative z-10">
                <h2 className="text-center mb-14 text-[#000000] font-semibold">
                    <FormattedMessage id="contact.title" />
                </h2>
                <form
                    onSubmit={handleSubmit}
                    className="max-w-3xl mx-auto bg-[#ffffff] p-8 shadow-md grid grid-cols-1 gap-6"
                >
                    <div className="grid grid-cols-2 gap-6 mb-4">
                        <div>
                            <label
                                htmlFor="firstName"
                                className="block mb-2 text-[#000000]"
                            >
                                <FormattedMessage id="contact.firstName" />
                            </label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-[#000000] rounded-lg" // Added rounded for input
                                required
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="lastName"
                                className="block mb-2 text-[#000000]"
                            >
                                <FormattedMessage id="contact.lastName" />
                            </label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-[#000000] rounded-lg" // Added rounded for input
                                required
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block mb-2 text-[#000000]"
                        >
                            <FormattedMessage id="contact.email" />
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-[#000000] rounded-lg" // Added rounded for input
                            required
                        />
                    </div>
                    <div className="mb-4">
                        {" "}
                        {/* Moved service selection here */}
                        <label className="block mb-2 text-[#000000]">
                            <FormattedMessage id="contact.service" />
                        </label>
                        <div className="flex space-x-4">
                            {" "}
                            {/* Use flexbox for inline layout */}
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="service"
                                    value="legal"
                                    checked={formData.service.includes("legal")}
                                    onChange={handleChange}
                                    className="custom-checkbox" // Updated class
                                />
                                <span>
                                    <FormattedMessage id="contact.legal" />
                                </span>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="service"
                                    value="translation"
                                    checked={formData.service.includes(
                                        "translation"
                                    )}
                                    onChange={handleChange}
                                    className="custom-checkbox" // Updated class
                                />
                                <span>
                                    <FormattedMessage id="contact.translation" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        {" "}
                        {/* Ensure the message box is full width */}
                        <label
                            htmlFor="message"
                            className="block mb-2 text-[#000000]"
                        >
                            <FormattedMessage id="contact.message" />
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            rows="6" // Increased rows for a bigger message box
                            className="w-full px-3 py-2 border border-[#000000] rounded-lg resize-none" // Added resize-none to prevent resizing
                        ></textarea>
                    </div>
                    {errorMessage && (
                        <div className="text-red-500 mb-4">{errorMessage}</div>
                    )}{" "}
                    {/* Display error message */}
                    <button
                        type="submit"
                        className="bg-[#690f39] text-[#ede6d6] w-1/4 px-6 py-3 font-semibold hover:bg-opacity-90 rounded-full transition duration-300 relative z-20" // Changed width to half
                    >
                        <FormattedMessage id="contact.submit" />
                    </button>
                </form>
            </div>
        </section>
    );
}

export default Contact;
