import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "../contexts/LanguageContext";
import { Link } from "react-router-dom";

function Header() {
    const { locale, setLocale } = useContext(LanguageContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <header className="bg-[#690f39] text-[#ede6d6] py-6 md:py-8 px-5 font-georgia sticky top-0 w-full z-50">
            <div className="container mx-auto flex flex-wrap justify-between items-center font-bold">
                <Link
                    to="/"
                    className="text-2xl md:text-3xl lg:text-4xl hover:text-gray-200 mb-2 md:mb-0"
                >
                    <FormattedMessage id="header.title" />
                    <span className="animate-flash">.</span>
                </Link>
                <button onClick={toggleMenu} className="md:hidden">
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16m-7 6h7"
                        />
                    </svg>
                </button>
                <nav
                    className={`w-full md:w-auto ${
                        isMenuOpen ? "block" : "hidden"
                    } md:block`}
                >
                    <ul className="flex flex-col md:flex-row md:items-center md:space-x-8 space-y-3 md:space-y-0 mt-5 md:mt-0">
                        <li>
                            <Link to="/" className="hover-underline-animation">
                                <span className="text-md font-medium">
                                    <FormattedMessage id="nav.home" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/about"
                                className="hover-underline-animation"
                            >
                                <span className="text-md font-medium">
                                    <FormattedMessage id="nav.about" />
                                </span>
                            </Link>
                        </li>
                        <li className="relative">
                            <button
                                onMouseEnter={() => setIsServicesOpen(true)} // Show on hover
                                className="hover-underline-animation flex items-center"
                            >
                                <span className="text-md font-medium">
                                    <FormattedMessage id="nav.services" />
                                </span>
                            </button>
                            <div
                                onMouseEnter={() => setIsServicesOpen(true)} // Keep open on hover
                                onMouseLeave={() => setIsServicesOpen(false)} // Hide on mouse leave
                            >
                                {isServicesOpen && (
                                    <ul className="absolute left-0 mt-2 w-26 bg-[#171c2f] shadow-lg py-1 z-10">
                                        <li>
                                            <Link
                                                to="/translation"
                                                className="block px-4 py-2 text-sm hover-underline-animation"
                                                onClick={() =>
                                                    setIsServicesOpen(false)
                                                }
                                            >
                                                <FormattedMessage id="nav.translation" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/legal"
                                                className="block px-4 py-2 text-sm hover-underline-animation"
                                                onClick={() =>
                                                    setIsServicesOpen(false)
                                                }
                                            >
                                                <FormattedMessage id="nav.legal" />
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </li>
                        <li>
                            <Link
                                to="/templates"
                                className="hover-underline-animation"
                            >
                                <span className="text-md font-medium">
                                    <FormattedMessage id="nav.templates" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/booking"
                                className="hover-underline-animation"
                            >
                                <span className="text-md font-medium">
                                    <FormattedMessage id="nav.bookCall" />
                                </span>
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center space-x-3 text-xs">
                                <button
                                    onClick={() => setLocale("fr")}
                                    className={`font-medium ${
                                        locale === "fr"
                                            ? "text-white"
                                            : "text-[#ECE4DA] hover:text-white"
                                    }`}
                                    aria-label="Français"
                                >
                                    FR
                                </button>
                                <span className="text-gray-300">|</span>
                                <button
                                    onClick={() => setLocale("en")}
                                    className={`font-medium ${
                                        locale === "en"
                                            ? "text-white"
                                            : "text-[#ede6c6] hover:text-white"
                                    }`}
                                    aria-label="English"
                                >
                                    EN
                                </button>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
