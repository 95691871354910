import React, { createContext, useState } from "react";

// Define available languages
export const LANGUAGES = {
    EN: "en",
    FR: "fr",
};

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [locale, setLocale] = useState("en"); // Default language

    return (
        <LanguageContext.Provider value={{ locale, setLocale }}>
            {children}
        </LanguageContext.Provider>
    );
};
