import React from "react";
import { FormattedMessage } from "react-intl";

function Privacy() {
    return (
        <div className="flex-grow">
            <main className="flex items-stretch">
                <section
                    id="about"
                    className="w-full bg-[#f8f9fa] font-pt py-20 flex-grow"
                >
                    <div className="container mx-auto px-4 flex flex-col md:flex-row items-start justify-center">
                        <div className="w-full md:w-2/3 text-center md:text-left md:pl-8 font-marcellus">
                            <h1 className="text-5xl sm:text-4xl md:text-6xl text-[#000000] font-bold leading-tight mb-6">
                                <FormattedMessage id="privacy.title" />
                            </h1>
                            <h2 className="text-base text-[#000000] leading-tight mt-6 mb-4">
                                <FormattedMessage id="privacy.h1" />
                            </h2>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h1.a" />
                                <a href="https://www.lexconnect.ch">
                                    <FormattedMessage id="privacy.h1.b" />
                                </a>
                                <FormattedMessage id="privacy.h1.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h1.d" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h1.e" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h1.f" />
                            </p>
                            <h2 className="text-base text-[#000000] leading-tight mt-4 mb-4">
                                <FormattedMessage id="privacy.h2" />
                            </h2>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h2.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h2.b" />
                            </p>
                            <h2 className="text-base text-[#000000] leading-tight mt-4 mb-4">
                                <FormattedMessage id="privacy.h3" />
                            </h2>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h3.a" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h3.b" />
                            </p>
                            <ul className="list-disc list-inside">
                                <li>
                                    <FormattedMessage id="privacy.h3.p1" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.h3.p2" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.h3.p3" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.h3.p4" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.h3.p5" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.h3.p6" />
                                </li>
                            </ul>
                            <p className="mb-4 mt-4">
                                <FormattedMessage id="privacy.h3.c" />
                            </p>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h3.d" />
                            </p>
                            <h2 className="text-base text-[#000000] leading-tight mt-4 mb-4">
                                <FormattedMessage id="privacy.h4" />
                            </h2>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h4.a" />
                            </p>
                            <ul className="list-disc list-inside">
                                <li>
                                    <FormattedMessage id="privacy.h4.p1" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.h4.p2" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.h4.p3" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.h4.p4" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.h4.p5" />
                                </li>
                                <li>
                                    <FormattedMessage id="privacy.h4.p6" />
                                </li>
                            </ul>
                            <p className="mb-4 mt-4">
                                <FormattedMessage id="privacy.h4.b" />
                            </p>
                            <h2 className="text-base text-[#000000] leading-tight mt-4 mb-4">
                                <FormattedMessage id="privacy.h5" />
                            </h2>
                            <p className="mb-4">
                                <FormattedMessage id="privacy.h5.a" />
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Privacy;
