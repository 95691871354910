import React from "react";

function Booking() {
    return (
        <div className="flex flex-col min-h-screen">
            <main className="flex-grow">
                <h1>Booking Page</h1>
                {/* Add your booking page content here */}
            </main>
        </div>
    );
}

export default Booking;
