import React, { useContext, useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import { LanguageContext, LanguageProvider } from "./contexts/LanguageContext";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Translation from "./components/Translation";
import Legal from "./components/Legal";
import About from "./components/About";
import Booking from "./components/Booking";
import Privacy from "./components/Privacy";
import messages from "./messages";
import Templates from "./components/Templates";

function App() {
    return (
        <LanguageProvider>
            <LanguageWrapper>
                <Router>
                    <AppContent />
                </Router>
            </LanguageWrapper>
        </LanguageProvider>
    );
}

function AppContent() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Layout>
            <TransitionGroup className="transition-wrapper">
                <CSSTransition
                    key={location.pathname}
                    classNames="fade"
                    timeout={1000} // Updated from 800 to 1000
                >
                    <Routes location={location}>
                        <Route path="/" element={<Home />} />
                        <Route path="/translation" element={<Translation />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/booking" element={<Booking />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/templates" element={<Templates />} />
                        <Route path="/legal" element={<Legal />} />
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
        </Layout>
    );
}

function LanguageWrapper({ children }) {
    const { locale } = useContext(LanguageContext);
    return (
        <IntlProvider
            messages={messages[locale]}
            locale={locale}
            defaultLocale="en"
        >
            {children}
        </IntlProvider>
    );
}

export default App;
