import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import package1Image from "../assets/images/placeholder.png";
import package2Image from "../assets/images/superior.png";
import package3Image from "../assets/images/superiorplus.png";
import Contact from "./Contact";

function Translation() {
    const packages = [
        {
            id: 1,
            title: <FormattedMessage id="translation.basic" />,
            image: package1Image,
            points: [
                {
                    title: <FormattedMessage id="translation.needs" />,
                    description: (
                        <FormattedMessage id="translation.basic.needs.description" />
                    ),
                },
                {
                    title: <FormattedMessage id="translation.features" />,
                    description: (
                        <FormattedMessage id="translation.basic.features.description" />
                    ),
                },
            ],
            price: <FormattedMessage id="translation.basic.price" />,
        },
        {
            id: 2,
            title: <FormattedMessage id="translation.superior" />,
            image: package2Image,
            points: [
                {
                    title: <FormattedMessage id="translation.needs" />,
                    description: (
                        <FormattedMessage id="translation.superior.needs.description" />
                    ),
                },
                {
                    title: <FormattedMessage id="translation.features" />,
                    description: (
                        <FormattedMessage id="translation.superior.features.description" />
                    ),
                },
            ],
            price: <FormattedMessage id="translation.superior.price" />,
        },
        {
            id: 3,
            title: <FormattedMessage id="translation.superior.plus" />,
            image: package3Image,
            points: [
                {
                    title: <FormattedMessage id="translation.needs" />,
                    description: (
                        <FormattedMessage id="translation.superior.plus.needs.description" />
                    ),
                },
                {
                    title: <FormattedMessage id="translation.features" />,
                    description: (
                        <FormattedMessage id="translation.superior.plus.features.description" />
                    ),
                },
            ],
            price: <FormattedMessage id="translation.superior.plus.price" />,
        },
    ];

    const faqItems = [
        {
            question: <FormattedMessage id="translation.faq.question1" />,
            answer: <FormattedMessage id="translation.faq.answer1" />,
        },
        {
            question: <FormattedMessage id="translation.faq.question2" />,
            answer: <FormattedMessage id="translation.faq.answer2" />,
        },
        {
            question: <FormattedMessage id="translation.faq.question3" />,
            answer: <FormattedMessage id="translation.faq.answer3" />,
        },
        {
            question: <FormattedMessage id="translation.faq.question4" />,
            answer: <FormattedMessage id="translation.faq.answer4" />,
        },
        {
            question: <FormattedMessage id="translation.faq.question5" />,
            answer: <FormattedMessage id="translation.faq.answer5" />,
        },
        {
            question: <FormattedMessage id="translation.faq.question6" />,
            answer: <FormattedMessage id="translation.faq.answer6" />,
        },
        // Add more FAQ items as needed
    ];

    const scrollToContact = () => {
        const contactSection = document.getElementById("contact");
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: "smooth" });
        }
    };

    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    return (
        <div className="flex-grow">
            <div className="">
                <div className="container mx-auto px-4 py-20">
                    <div className="flex flex-col md:flex-row md:space-x-8">
                        {/* Left side */}
                        <div className="md:w-1/2 mb-8 md:mb-0">
                            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-[#000000] mb-10">
                                <FormattedMessage id="translation.title" />
                            </h1>
                            <p className="text-lg text-[#000000] mb-8">
                                <FormattedMessage id="translation.description" />
                            </p>
                            <button
                                onClick={scrollToContact}
                                className="bg-[#000000] text-white text-lg  px-6 py-3 rounded-full hover:bg-opacity-90 transition-colors duration-300"
                            >
                                <FormattedMessage id="translation.contactButton" />
                            </button>
                        </div>

                        {/* Right side */}
                        <div className="md:w-1/2">
                            <div className="space-y-0">
                                {["block1", "block2", "block3", "block4"].map(
                                    (block, index) => (
                                        <Dropdown
                                            key={index}
                                            title={
                                                <FormattedMessage
                                                    id={`translation.${block}.title`}
                                                />
                                            }
                                            description={
                                                <FormattedMessage
                                                    id={`translation.${block}.description`}
                                                />
                                            }
                                            isOpen={openDropdown === index}
                                            toggleDropdown={() =>
                                                toggleDropdown(index)
                                            }
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-[#ede6d6] py-20">
                <div className="container mx-auto px-4">
                    <h2 className="font-bold text-center">
                        <FormattedMessage id="translation.packages" />
                    </h2>
                    <h3 className="text-center font-semibold mt-10 md:mt-5">
                        <FormattedMessage id="translation.packagesText" />
                    </h3>
                    <p className="text-center mt-10 md:mt-5">
                        <FormattedMessage id="translation.packagesText2" />
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
                        {packages.map((pkg) => (
                            <div
                                className=" overflow-hidden shadow-lg p-4"
                                key={pkg.id}
                            >
                                <PackageCard pkg={pkg} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-[#ffffff] py-20">
                <div className="container mx-auto px-4">
                    <h2 className=" font-bold pb-10 text-center">
                        <FormattedMessage id="translation.faq" />
                    </h2>
                    <FAQ items={faqItems} />
                </div>
            </div>
            <Contact id="contact" />
        </div>
    );
}

function Dropdown({ title, description, isOpen, toggleDropdown }) {
    return (
        <div className="border-b border-gray-200 last:border-b-0">
            <button
                onClick={toggleDropdown}
                className="flex justify-between items-center w-full p-4 text-left text-[#000000]"
            >
                <span className="text-xl font-merriweather">{title}</span>
                <span className="text-2xl ">{isOpen ? "-" : "+"}</span>
            </button>
            {isOpen && (
                <div className="p-4">
                    <p className="text-[#000000]">{description}</p>
                </div>
            )}
        </div>
    );
}

function PackageCard({ pkg }) {
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    return (
        <div className=" p-4 max-w-xs mx-auto md:max-w-md lg:max-w-lg">
            <img
                src={pkg.image}
                alt={pkg.title}
                className="w-full h-auto mb-2"
            />

            {pkg.points.map((point, index) => (
                <Dropdown
                    key={index}
                    title={point.title}
                    description={point.description}
                    isOpen={openDropdown === index}
                    toggleDropdown={() => toggleDropdown(index)}
                />
            ))}
            <p className="text-xl font-bold mt-2 mb-2">{pkg.price}</p>
        </div>
    );
}

function FAQ({ items }) {
    const [openItem, setOpenItem] = useState(null);

    const toggleItem = (index) => {
        setOpenItem(openItem === index ? null : index);
    };

    return (
        <div className="space-y-0 max-w-4xl mx-auto">
            {items.map((item, index) => (
                <div
                    key={index}
                    className="border-b border-gray-200 last:border-b-0"
                >
                    <button
                        className="flex justify-between items-center w-full p-4 text-left text-[#000000]"
                        onClick={() => toggleItem(index)}
                    >
                        <span className="text-xl font-merriweather">
                            {item.question}
                        </span>
                        <span className="text-2xl">
                            {openItem === index ? "-" : "+"}
                        </span>
                    </button>
                    {openItem === index && (
                        <div className="p-4">
                            <p className="text-[#000000]">{item.answer}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default Translation;
