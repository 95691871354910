import axios from "axios";
import { FormattedMessage } from "react-intl";
import { loadStripe } from "@stripe/stripe-js";
import package1Image from "../assets/images/placeholder.png";
import package2Image from "../assets/images/superior.png";

function Templates() {
    const products = [
        {
            id: 1,
            title: "Service Agreement",
            image: package1Image,
            description: "Standard service agreement template",
            price: 19.99,
            stripe_price_id: "price_1234567890", // Replace with actual Stripe Price ID
        },
        {
            id: 2,
            title: "Non Disclosure Agreement",
            image: package2Image,
            description: "Comprehensive NDA template",
            price: 24.99,
            stripe_price_id: "price_0987654321", // Replace with actual Stripe Price ID
        },
    ];

    return (
        <div className="bg-[#171c2f] pb-20">
            <div className="container mx-auto px-4 py-12">
                <h2 className="text-5xl pt-10 pb-20 text-left text-[#f8f9fa]">
                    <FormattedMessage id="templates.packages" />
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {products.map((product) => (
                        <PackageCard key={product.id} pkg={product} />
                    ))}
                </div>
            </div>
        </div>
    );
}

function PackageCard({ pkg }) {
    return (
        <div className="bg-[#f8f9fa] p-4">
            <img
                src={pkg.image}
                alt={pkg.title}
                className="w-full h-auto mb-4"
            />
            <h3 className="text-xl font-bold mb-2">{pkg.title}</h3>
            <p className="mb-4">{pkg.description}</p>
            <p className="text-2xl font-bold mt-4 mb-6 text-[#3d3d3d]">
                ${pkg.price.toFixed(2)}
            </p>
            <PurchaseButton pkg={pkg} />
        </div>
    );
}

function PurchaseButton({ pkg }) {
    const handleClick = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/create-checkout-session`,
                {
                    productName: pkg.title,
                    priceId: pkg.stripe_price_id,
                }
            );

            const { sessionId } = response.data;

            // Redirect to Stripe Checkout
            const stripe = await loadStripe(
                process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
            );
            stripe.redirectToCheckout({ sessionId });
        } catch (error) {
            console.error("Error creating checkout session:", error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    return (
        <button
            onClick={handleClick}
            className="w-full bg-[#171c2f] text-[#f8f9fa] px-4 py-2 hover:bg-opacity-90 transition-colors duration-300"
        >
            <FormattedMessage id="templates.purchaseButton" />
        </button>
    );
}

export default Templates;
