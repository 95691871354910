import React from "react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

function Footer() {
    const encodedEmail =
        "&#97;&#108;&#101;&#120;&#97;&#110;&#100;&#114;&#97;&#64;&#108;&#101;&#120;&#99;&#111;&#110;&#110;&#101;&#99;&#116;&#46;&#99;&#104;"; // Corrected email encoding

    const decodeEmail = () => {
        return encodedEmail.replace(/&#(\d+);/g, (match, dec) =>
            String.fromCharCode(dec)
        );
    };

    return (
        <footer className="bg-[#690f39] text-[#ede6d6] py-6 font-merriweather">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
                <div className="flex-1 flex justify-center mb-4 md:mb-0">
                    <div className="flex flex-col items-center">
                        <p className="text-xs sm:text-sm md:text-base">
                            <FormattedMessage id="footer.swiss" />
                        </p>
                        <p className="text-xs sm:text-sm md:text-base">
                            <FormattedMessage id="footer.location" />
                        </p>
                    </div>
                </div>
                <div className="hidden md:block w-px h-20 bg-[#ede6d6] mx-4"></div>
                <div className="flex-1 flex justify-center mb-4 md:mb-0">
                    <p className="text-center text-xs sm:text-sm md:text-base">
                        Email:{" "}
                        <a
                            href={`mailto:${decodeEmail()}`}
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = `mailto:${decodeEmail()}`;
                            }}
                            className="hover:text-[#ede6d6]/80"
                        >
                            {decodeEmail()}
                        </a>
                    </p>
                </div>
                <div className="hidden md:block w-px h-20 bg-[#ede6d6] mx-4"></div>
                <div className="flex-1 flex justify-center mb-4 md:mb-0">
                    <div className="flex flex-col items-center">
                        <p className="text-xs sm:text-sm md:text-base">
                            <FormattedMessage id="footer.newSection" />
                        </p>
                        <p className="text-xs sm:text-sm md:text-base">
                            Additional content here
                        </p>
                    </div>
                </div>
                <div className="hidden md:block w-px h-20 bg-[#ede6d6] mx-4"></div>
                <div className="flex-1 flex justify-center">
                    <div className="flex items-center">
                        <a
                            href="https://instagram.com"
                            className="mr-4 hover:text-[#f9f9f1]/80 text-3xl sm:text-3xl md:text-4xl"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a
                            href="https://linkedin.com"
                            className="hover:text-[#f9f9f1]/80 text-3xl sm:text-3xl md:text-4xl"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="mt-4 text-center flex flex-col md:flex-row justify-between">
                <p className="flex-1 text-center text-xs sm:text-sm md:text-base">
                    &copy; {new Date().getFullYear()}{" "}
                    <FormattedMessage id="footer.copyright" />{" "}
                    <a href="/Privacy">
                        <FormattedMessage id="footer.privacy" />
                    </a>
                </p>
            </div>
        </footer>
    );
}

export default Footer;
