const messages = {
    en: {
        // Header
        "header.title": "LEX Connect.",

        // Navigation
        "nav.home": "Home",
        "nav.about": "About",
        "nav.services": "Services",
        "nav.translation": "Translation",
        "nav.legal": "Legal Compliance",
        "nav.templates": "Templates",
        "nav.bookCall": "Book a Call",

        // Hero section
        "hero.title": "Legal Insight with a Global Touch",
        "hero.button": "About",
        "hero.description":
            "I'm Alexandra, an independent legal consultant and freelance nomad with international experience. My unique background allows me to combine two key areas of expertise:",

        "hero.point1": "Legal services: ",
        "hero.point2": "Linguistic services: ",
        "hero.point1a": "where my journey began.",
        "hero.point2a": "translation and copywriting.",

        "hero.description2": "Want to learn more about me?",
        "hero.description3":
            "Whether you’re looking to strengthen your legal compliance or expand into French-speaking markets, one thing is certain: you’re not alone.",
        "hero.description4":
            "I’m here to make your life easier so you can focus on what you do best—growing your business.",
        "hero.description5":
            "Please note: As an independent legal consultant not registered with the bar, I provide legal services based on my expertise. However, I am not authorized to represent clients in court or offer services reserved for licensed attorneys. My services do not replace legal advice from a qualified attorney.",

        // Contact form
        "contact.title": "Get in Touch",
        "contact.error":
            "Please fill in all required fields and select at least one service.",
        "contact.firstName": "First Name",
        "contact.lastName": "Last Name",
        "contact.email": "Email",
        "contact.message": "Your message",
        "contact.service": "Services you are interested in",
        "contact.legal": "Legal",
        "contact.translation": "Translation",
        "contact.submit": "Submit",

        // Footer
        "footer.swiss": "Switzerland",
        "footer.location": "Virtually anywhere",
        "footer.copyright": "LEX Connect. All rights reserved.",
        "footer.privacy": "Privacy Policy",
        // About page
        "about.title":
            "Hello, I'm Alexandra—Your Partner in Law, Language, and Communication",
        "about.subtitle":
            "Welcome! I'm thrilled you've stopped by Let’s imagine we’re sharing a virtual coffee as I introduce myself and explain how I can assist you and your business.",
        "about.t1": "A Blend of Legal Expertise and Multicultural Insight",
        "about.p1":
            "My journey began with a law degree from the University of Louvain in Belgium, complemented by a management degree from LLC. My professional path took me to Luxembourg, where I honed my skills as a legal consultant and corporate officer for various firms.",
        "about.p1.a":
            "After six years in the corporate legal world, I felt the need to broaden my horizons. I took a step back to immerse myself in new cultures and gain fresh perspectives. I’ve always believed that stepping out of your comfort zone and experiencing different ways of life enriches your worldview—and your expertise.",
        "about.p1.b":
            "In 2020, during the pandemic, I found myself stuck outside of Europe and discovered a new passion: copywriting and communication. As I helped businesses connect with French-speaking audiences, I realized how these new skills complemented my foundation in legal precision and rigor.",
        "about.t2": "Where Legal Precision Meets Linguistic Precision",
        "about.p2":
            "You might wonder how legal services, language expertise, and copywriting intertwine. The key is precision. Whether crafting persuasive copy or reviewing a legal document, every word matters. I apply the same meticulous attention to detail across both areas, ensuring your content is both compelling and legally sound.",
        "about.t3": "My Nomadic Journey and Entrepreneurial Spirit",
        "about.p3":
            "Beyond my professional life, I've embraced the world as my office. As a digital nomad, I've had the opportunity to travel extensively, deepening my understanding of different cultures and connecting with a diverse range of clients globally.",
        "about.p3.a":
            "This global lifestyle has taught me a valuable lesson—we're all navigating the complexities of life and business together. This insight is at the core of how I approach my work. I understand the challenges of running a business in today’s dynamic world because I experience them myself.",
        "about.t4": "My Approach",
        "about.p4":
            "When you choose to work with me, you’re gaining more than just a service provider—you’re gaining a dedicated partner. I believe in keeping things straightforward, transparent, and personal. I'm here to ensure you can focus on what you do best.",
        "about.t5": "Lets Connect!",
        "about.p5":
            "Whether you’re looking to navigate legal intricacies, or want to expand into French-speaking markets, I’m here to help. Let’s work together to make your business as successful and stress-free as possible.",
        "about.p5.a": "I look forward to connecting with you!",

        // Work together section
        "work.title": "We should work together if",
        "work.section1": "You Want to Focus on Your Core Business",
        "work.description1":
            "Let us handle the legal and translation details, so you can concentrate on what truly matters—growing and managing your business.",
        "work.section2": "You Need Tailored Solutions",
        "work.description2":
            "Your business is unique, and we customize our services to meet your specific needs.",
        "work.section3": "You’re Looking for a Long-Term Partner",
        "work.description3":
            "You want a partner who listens, provides clear guidance, and keeps you informed every step of the way.",

        // Translation page
        "translation.title": "Translation",
        "translation.description":
            "I provide flexible pricing options designed to suit your specific needs. Whether you prefer hourly rate or a fixed fee, we can tailor the best solution for your project.",
        "translation.packages": "Packages",
        "translation.services": "Services",
        "translation.packagesText": "Looking for an All-in-One Solution?",
        "translation.packagesText2":
            "Choose the package that best fits your needs, with or without additional add-ons, and enjoy a streamlined process from start to finish.",
        "translation.contactButton": "Contact me",

        // Translation block
        "translation.block1.title": "Translation",
        "translation.block1.description":
            "Flat Translations: Automated tools lack the depth and nuance needed to truly capture your message.",
        "translation.block2.title": "Copywriting",
        "translation.block2.description":
            "SEO Gaps: No specific keywords for French search engines.",
        "translation.block3.title": "Editing",
        "translation.block3.description": "Editing",
        "translation.block4.title": "SEO",
        "translation.block4.description": "SEO",

        // Translation Basic Package
        "translation.basic": "Basic",
        "translation.needs": "For you if:",
        "translation.basic.needs.description":
            "For businesses that need a simple and effective translation solution.",
        "translation.features": "Package Includes",
        "translation.basic.features.description":
            "For businesses that need a simple and effective translation solution.",
        "translation.basic.price": "999 CHF",

        // Translation Superior Package
        "translation.superior": "Superior",

        "translation.superior.needs.description":
            "For businesses that need a simple and effective translation solution.",

        "translation.superior.features.description":
            "For businesses that need a simple and effective translation solution.",
        "translation.superior.price": "1999 CHF",

        // Translation Superior Plus Package
        "translation.superior.plus": "Superior+",

        "translation.superior.plus.needs.description":
            "For businesses that need a simple and effective translation solution.",

        "translation.superior.plus.features.description":
            "For businesses that need a simple and effective translation solution.",
        "translation.superior.plus.price": "2999 CHF",

        // Translation faq
        "translation.faq": "Frequently Asked Questions",
        "translation.faq.question1": "What translation services do you offer?",
        "translation.faq.answer1":
            "I offer a range of translation services tailored to your needs, including, but not limited to, marketing, website, and legal translations. My goal is to ensure that your message is accurately conveyed in the target language, while maintaining the tone and intent of the original content.",
        "translation.faq.question2": "What languages do you translate?",
        "translation.faq.answer2":
            "I specialize in translating from English to French. However, I can also handle translations from German into French when needed.",
        "translation.faq.question3":
            "What are your rates for translation and copywriting services?",
        "translation.faq.answer3":
            "My rates vary depending on the complexity and scope of the project. I offer competitive pricing based on factors such as word count, technicality, and turnaround time. For a detailed quote tailored to your specific needs, please feel free to contact me directly.",
        "translation.faq.question4": "Can you handle urgent projects?",
        "translation.faq.answer4":
            "Yes, I can accommodate urgent projects. Please be aware that such projects may incur an additional fee due to the expedited turnaround. While I strive to meet tight deadlines, availability may vary depending on the complexity and volume of the work. For the best results, it’s always helpful to provide as much advance notice as possible. Feel free to contact me with your project details, and I’ll do my best to assist you promptly.",
        "translation.faq.question5":
            "Do you offer any additional services related to translation or copywriting?",
        "translation.faq.answer5":
            "Absolutely! Besides translating and copywriting, I’m your go-to for all sorts of extra services. Need proofreading? Check. SEO optimization? You bet. If you have other needs or specific requests, just let me know—I’m here to help with a range of related services to support your goals.",
        "translation.faq.question6":
            "Why should I choose a translation package instead of individual services?",
        "translation.faq.answer6":
            "If you’re looking for a cohesive and efficient solution, a translation package might be just what you need. Feel free to reach out if you have any questions or need help selecting the right package for your project.",

        // Legal page

        // Privacy page
        "privacy.title": "Privacy Policy",

        "privacy.h1": "1. Preliminary Notes",
        "privacy.h1.a":
            "Protecting your personal data is our highest priority. We only collect information that is strictly necessary for the use of our website, ",
        "privacy.h1.b": "www.lexconnect.ch ",
        "privacy.h1.c":
            "(the “Website”), and for delivering our products and services. All data collected is handled with the utmost care",
        "privacy.h1.d":
            "We comply with the Swiss Federal Data Protection Act (“DPA”) and, where applicable, the EU General Data Protection Regulation (“GDPR”) in our collection and processing of personal data.",
        "privacy.h1.e":
            "This Privacy Policy (the “Policy”) details our practices regarding the collection, storage, use, and disclosure of personal data through our Website.",
        "privacy.h1.f":
            "Please read this Policy carefully. If you do not agree with its terms, we ask that you refrain from using the Website and our services.",

        "privacy.h2": "2. Scope of the Policy",
        "privacy.h2.a":
            "This Policy applies to the use of the Website, operated by Alexandra Dallüge, and to all personal data collected through the Website.",
        "privacy.h2.b":
            " Please be aware that our Website may include links to third-party websites, which are governed by their own privacy policies. We are not responsible for how these third parties collect, use, or protect your personal data. We recommend that you review the privacy policies of any third-party sites before providing them with your personal information.",

        "privacy.h3": "3. Types of Data Collected",
        "privacy.h3.a":
            "When you visit the Website, our web server automatically records certain details about your visit, including your IP address, the referring website, your browser type, and the pages you view, along with the date and duration of your visit.",
        "privacy.h3.b":
            "Additionally, we collect personal data that you provide directly through the Website, such as when you fill out a contact form, download a resource, schedule an appointment, or make a purchase. The types of data collected include:",
        "privacy.h3.p1": "Identity Data: Your first and last names.",
        "privacy.h3.p2":
            "Contact Data: Addresses (billing address, email address) and phone numbers.",
        "privacy.h3.p3":
            "Financial Data: Bank details, payment card information, and payment history.",
        "privacy.h3.p4":
            "Service Data: Information about the services you have received or provided.",
        "privacy.h3.p5":
            "Professional Information: Details related to your job functions and professional activities.",
        "privacy.h3.p6":
            "Other Information: Any additional information shared during our interactions.",
        "privacy.h3.c":
            "We are committed to handling this information confidentially and using it solely for the purposes for which it was collected.",
        "privacy.h3.d":
            "We do not process sensitive data as defined in Article 5(c) of the DPA.",

        "privacy.h4": "4. Purpose of Data Processing",
        "privacy.h4.a":
            "When you use our services, browse the Website, or contact us, we collect and process your personal data for the following purposes:",
        "privacy.h4.p1":
            "Communication: To engage with you and verify your identity.",
        "privacy.h4.p2":
            "Service Provision: To prepare, conclude, and document contracts necessary for delivering our services, as well as for billing purposes.",
        "privacy.h4.p3":
            "Order Management: To handle and process orders placed on the Website.",
        "privacy.h4.p4":
            "Free Resource Distribution: When you download free resources from our Website, we collect your email address to monitor interest in our offerings.",
        "privacy.h4.p5":
            "Legitimate Interests: To enhance our services and prevent fraudulent activities.",
        "privacy.h4.p6":
            "Legal Compliance: To fulfill legal obligations, including those related to accounting, taxation, and data protection.",
        "privacy.h4.b":
            "The legal bases for processing your data are primarily your consent, the performance of a contract, our legitimate interests, and compliance with a legal obligation, in accordance with Article 6(1) of the GDPR.",

        "privacy.h5": "5. Data Transfer to Third Parties",
        "privacy.h5.a":
            "We are committed to protecting your data and do not sell it to third parties. However, to effectively provide our products and services, we may need to share certain information with partners or external service providers.",
        "privacy.h5.b": "We may disclose your data to the following entities:",

        "privacy.h6": "6. Your Rights",
        "privacy.h6.a":
            "You have the right to access, correct, delete, or restrict the processing of your personal data, as well as the right to object to its processing. You can also request that we transfer your data to another service provider.",
    },
    fr: {
        // Header
        "header.title": "LEX Connect.",

        // Navigation
        "nav.home": "Accueil",
        "nav.about": "À propos",
        "nav.services": "Services",
        "nav.translation": "Traduction",
        "nav.legal": "Droit",
        "nav.bookCall": "Réserver un appel",

        // Hero section
        "hero.title": "Conseiller en langue et en droit.",
        "hero.button": "Entrer en contact",
        "hero.description":
            "Bonjour, je m’appelle Alexandra, une digital nomade déterminée à aider les entreprises et les organisations à atteindre leurs objectifs grâce à une communication efficace et une conformité légale.",
        "hero.description2":
            "Spécialisée dans la traduction en anglais et en français, ainsi que dans les services juridiques pour la Suisse.",

        // About page
        "about.title": "Bonjour! Je m'appelle Alexandra",
        "about.subtitle":
            "Merci de passer par là. Prenez un café virtuel, et laissez-moi vous parler un peu de comment je peux vous aider et votre entreprise.",
        "about.h1": "Ce que je fais",
        "about.d1":
            "Je suis ici pour vous assurer que votre entreprise fonctionne correctement du point de vue juridique tout en vous aidant à briller sur les marchés francophones. Voici comment:",
        "about.h2": "Services de révision juridique",

        // ... other translations
    },
};

export default messages;
