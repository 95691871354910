import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const work = {
    title: <FormattedMessage id="work.title" />,
    section1: <FormattedMessage id="work.section1" />,
    description1: <FormattedMessage id="work.description1" />,
    section2: <FormattedMessage id="work.section2" />,
    description2: <FormattedMessage id="work.description2" />,
    section3: <FormattedMessage id="work.section3" />,
    description3: <FormattedMessage id="work.description3" />,
};

function Dropdown({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-4 w-full">
            {" "}
            {/* Added w-full here */}
            <button
                className="flex justify-between items-center w-full p-4 bg-[#690f39] text-[#ede6d6] text-left font-merriweather text-base sm:text-base md:text-lg"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{title}</span>
                <ChevronDownIcon
                    className={`w-6 h-6 transition-transform ${
                        isOpen ? "rotate-180" : ""
                    }`}
                />
            </button>
            {isOpen && (
                <div className="p-4 bg-[#690f39] text-[#ede6d6]">
                    <p className="">{content}</p>
                </div>
            )}
        </div>
    );
}

export default function Services() {
    return (
        <section className="bg-[#690f39] text-[#ede6d6]">
            <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-start">
                <div className="md:w-1/2 mb-8 md:mb-0 py-10 px-10">
                    <h2 className>{work.title}</h2>
                </div>
                <div className="md:w-1/2 flex flex-col items-center w-full">
                    {" "}
                    {/* Added w-full here */}
                    <div className="w-full">
                        {" "}
                        {/* Added wrapper for full width */}
                        <Dropdown
                            title={work.section1}
                            content={work.description1}
                        />
                        <hr className="border-t border-[#ede6d6] opacity-30 my-4" />
                        <Dropdown
                            title={work.section2}
                            content={work.description2}
                        />
                        <hr className="border-t border-[#ede6d6] opacity-30 my-4" />
                        <Dropdown
                            title={work.section3}
                            content={work.description3}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
